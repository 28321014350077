import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import TableBarIcon from "@mui/icons-material/TableBar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WebIcon from "@mui/icons-material/Web";
import { Box, CSSObject, Divider, IconButton, ListItem, Drawer as MuiDrawer, Theme, styled, useMediaQuery, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import SellIcon from "@mui/icons-material/Sell";

interface AdminLinks {
    text: string;
    icon: any;
    link: string;
    highlight: string;
    roleCheck?: (role: UserRole) => boolean;
}

const adminLinks: AdminLinks[] = [
    {
        text: "Dashboard",
        icon: DashboardIcon,
        link: "/admin",
        highlight: "admin",
    },
    // {
    //     text: "Activity",
    //     icon: ManageHistoryIcon,
    //     link: "/admin/activity",
    //     highlight: "admin/activity",
    // },
    {
        text: "Officers",
        icon: GroupsIcon,
        link: "/admin/officers",
        highlight: "admin/officers",
    },
    {
        text: "Events",
        icon: EventIcon,
        link: "/admin/events",
        highlight: "admin/events",
    },
    {
        text: "Roundtable",
        icon: TableBarIcon,
        link: "/admin/roundtable",
        highlight: "admin/roundtable",
    },
    {
        text: "Messages",
        icon: EmailIcon,
        link: "/admin/messages",
        highlight: "admin/messages",
    },
    // {
    //     text: "Pages",
    //     icon: WebIcon,
    //     link: "/admin/pages",
    //     highlight: "admin/pages",
    // },
    {
        text: "Documents",
        icon: UploadFileIcon,
        link: "/admin/docs",
        highlight: "admin/docs",
    },
    {
        text: "Shop",
        icon: SellIcon,
        link: "/admin/shop",
        highlight: "admin/shop",
    },
    {
        text: "Advanced",
        icon: SettingsIcon,
        link: "/admin/advanced",
        highlight: "admin/advanced",
        roleCheck: (role: UserRole) => role === "admin" || role === "root",
    },
];

const AdminLayout = ({ children }: { children: any }) => {
    const { siteState } = useContext(SiteStateContext);
    const [eventsOpen, setEventsOpen] = useState(siteState.navHighlight.includes("admin/events"));
    const [messagesOpen, setMessagesOpen] = useState(siteState.navHighlight.includes("admin/messages"));
    const { authUser, ready, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(!isMobile);

    const test = window.location.pathname;

    useEffect(() => {
        if (ready) {
            if (!authUser) {
                console.log(test);
                navigate(`/admin/login?to=${test}`);
            }
        }
    }, [ready, authUser, navigate]);

    useEffect(() => {
        if (authUser?.reset_password) {
            navigate("/admin/reset-password");
        }
    }, [authUser, navigate]);

    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);

    const handleEventsClick = () => {
        setEventsOpen(!eventsOpen);
    };

    const handleMessagesClick = () => {
        setMessagesOpen(!messagesOpen);
    };

    const drawerWidth = 240;

    const openedMixin = (theme: Theme): CSSObject => ({
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
    });

    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }));

    const authFilterdLinks = adminLinks.filter((link) => {
        if (link.roleCheck) {
            if (!authUser) return false;
            return link.roleCheck(authUser?.role);
        } else {
            return true;
        }
    });

    return (
        <Box sx={{ display: "flex", mb: 3 }}>
            <Drawer variant="permanent" open={open}>
                {/* <Toolbar /> */}
                <div style={{ marginTop: "50px" }}></div>
                {open ? (
                    <DrawerHeader>
                        <IconButton
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>
                ) : (
                    <IconButton
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                <Divider />

                <List>
                    {authFilterdLinks.map((link, index) => (
                        <ListItem key={index} disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                                onClick={() => {
                                    navigate(link.link);
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <link.icon />
                                </ListItemIcon>
                                <ListItemText primary={link.text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            {children}
        </Box>
    );
};

export default AdminLayout;
