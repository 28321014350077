import EventIcon from "@mui/icons-material/Event";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PeopleIcon from "@mui/icons-material/People";
import PinDropIcon from "@mui/icons-material/PinDrop";
import TodayIcon from "@mui/icons-material/Today";
import { Alert, Box, Button, CircularProgress, Container, Grid, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEventBySlug, registerForEvent } from "../api/event";
import MarkdownRender from "../components/MarkdownRender";
import { AuthContext } from "../contexts/AuthContext";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";
import RichRender from "../components/RichRender";
import gaEvents from "../util/gaEvent";
import { LoadingButton } from "@mui/lab";

const dateFormat: string = "MMM DD, YYYY h:mma";

function renderObject(obj: EventObject, value: string, setValue: (val: string) => void) {
    switch (obj.type) {
        case 4: // text
            return (
                <TextField
                    label={obj.title}
                    variant="standard"
                    fullWidth
                    required={obj.required}
                    value={value}
                    onChange={(ev) => {
                        setValue(ev.target.value);
                    }}
                    helperText={obj.helper_text ?? undefined}
                />
            );
        case 5: // dropdown
            return (
                <TextField
                    select
                    label={obj.title}
                    variant="standard"
                    fullWidth
                    required={obj.required}
                    value={value}
                    onChange={(ev) => {
                        setValue(ev.target.value);
                    }}
                    helperText={obj.helper_text ?? undefined}
                >
                    {obj.options.split(",")?.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            );
        default:
            return <></>;
    }
}

const EventPage = () => {
    const { setSiteState } = useContext(SiteStateContext);
    const [event, setEvent] = useState<PublicEventItem | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [registerInProgress, setRegisterInProgress] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [in_name, set_in_name] = useState<string>("");
    const [in_email, set_in_email] = useState<string>("");
    const [in_phone, set_in_phone] = useState<string>("");
    const [in_unit_type, set_in_unit_type] = useState<string>("troop");
    const [in_unit_number, set_in_unit_number] = useState<string>("");
    const [in_group, set_in_group] = useState<string>("youth");
    const [in_notes, set_in_notes] = useState<string>("");
    const [in_objects, set_in_objects] = useState<{ [key: string]: string }>({});

    const [successState, setSuccessState] = useState<"success" | "fail" | "unset">("unset");
    const [errorState, setErrorState] = useState<string>("");
    const [attemptingRegister, setAttemptingRegister] = useState<boolean>(false);

    const [eventClosed, setEventClosed] = useState<boolean>(false);
    const [adminEventOverride, setAdminEventOverride] = useState<boolean>(false);
    const { authUser } = useContext(AuthContext);

    const [disableActions, setDisableActions] = useState<boolean>(false);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: `${event?.title ?? "Event Not Found"} - Events`,
                    navHighlight: "/events",
                    actions: [
                        {
                            icon: ModeEditIcon,
                            text: "Edit Event",
                            disabled: disableActions,
                            onClick: () => {
                                navigate(`/admin/events/${event?.id}/edit`);
                            },
                        },
                        {
                            icon: PeopleIcon,
                            text: "View Registrants",
                            disabled: disableActions,
                            onClick: () => {
                                navigate(`/admin/events/${event?.id}/view`);
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, event, disableActions]);

    useEffect(() => {
        if (!params.slug) return setLoading(false);
        getEventBySlug(params.slug)
            .then((ev) => {
                if (ev.external) {
                    window.location.href = ev.link;
                    return;
                }
                if (authUser) setAdminEventOverride(true);
                setEventClosed(!ev.enabled);
                setEvent(ev);
                setLoading(false);
                gaEvents.events.view(ev.id);
            })
            .catch((err) => {
                setDisableActions(true);
                setLoading(false);
            });
    }, [location, params]);

    function resetInputs() {
        set_in_name("");
        set_in_email("");
        set_in_phone("");
        set_in_unit_type("troop");
        set_in_unit_number("");
        set_in_group("youth");
        set_in_notes("");
        set_in_objects({});
    }

    return (
        <Container>
            {loading ? (
                <CircularProgress />
            ) : event ? (
                <Paper sx={{ m: 4, p: 3 }}>
                    {eventClosed && adminEventOverride && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            This event is disabled — because you are an admin, you still see this event.
                        </Alert>
                    )}
                    {successState === "fail" && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {errorState ?? "An unknown error occurred."}
                        </Alert>
                    )}
                    {successState === "success" && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            You have successfully registered for this event!
                        </Alert>
                    )}
                    <Stack direction="row">
                        <Typography variant="h3">{event.title}</Typography>
                        {registerInProgress && (
                            <Button
                                size="small"
                                sx={{ marginLeft: "auto" }}
                                variant="outlined"
                                onClick={() => {
                                    setSuccessState("unset");
                                    setRegisterInProgress(false);
                                }}
                            >
                                Return to event
                            </Button>
                        )}
                    </Stack>
                    {eventClosed && !adminEventOverride ? (
                        <>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Registration is closed.
                            </Typography>
                        </>
                    ) : (
                        <>
                            {registerInProgress ? (
                                <Box sx={{ p: 3 }}>
                                    <Grid container spacing={3}>
                                        {event.input_name && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Name"
                                                    variant="standard"
                                                    fullWidth
                                                    required
                                                    value={in_name}
                                                    onChange={(ev) => {
                                                        set_in_name(ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Email"
                                                type="email"
                                                variant="standard"
                                                fullWidth
                                                required
                                                value={in_email}
                                                onChange={(ev) => {
                                                    set_in_email(ev.target.value);
                                                }}
                                            />
                                        </Grid>
                                        {event.input_phone && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Phone"
                                                    type="tel"
                                                    variant="standard"
                                                    fullWidth
                                                    required
                                                    value={in_phone}
                                                    onChange={(ev) => {
                                                        set_in_phone(ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {event.input_unit && (
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    select
                                                    label="Unit type"
                                                    variant="standard"
                                                    fullWidth
                                                    required
                                                    value={in_unit_type}
                                                    onChange={(ev) => {
                                                        set_in_unit_type(ev.target.value);
                                                    }}
                                                >
                                                    {["troop", "crew", "ship", "pack"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        )}
                                        {event.input_unit && (
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    label="Unit number"
                                                    type="number"
                                                    variant="standard"
                                                    fullWidth
                                                    required
                                                    value={in_unit_number}
                                                    onChange={(ev) => {
                                                        set_in_unit_number(ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {event.input_group && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    label="Age group"
                                                    variant="standard"
                                                    fullWidth
                                                    required
                                                    value={in_group}
                                                    onChange={(ev) => {
                                                        set_in_group(ev.target.value);
                                                    }}
                                                >
                                                    {[
                                                        { a: "youth", b: "Youth (<18)" },
                                                        { a: "ya", b: "Youth+ (18-21)" },
                                                        { a: "adult", b: "Adult (>21)" },
                                                    ].map((option) => (
                                                        <MenuItem key={option.a} value={option.a}>
                                                            {option.b}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        )}
                                        {event.objects?.length > 0 && (
                                            <Grid item xs={12}>
                                                {event.objects.map((obj) => {
                                                    if (in_objects[obj.id] === undefined) {
                                                        set_in_objects({ ...in_objects, [obj.id]: obj.default });
                                                    }
                                                    return renderObject(obj, in_objects[obj.id], (val) => {
                                                        console.log(val);
                                                        in_objects[obj.id] = val;
                                                        set_in_objects({ ...in_objects });
                                                    });
                                                })}
                                            </Grid>
                                        )}
                                        {event.input_notes && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Notes"
                                                    variant="standard"
                                                    fullWidth
                                                    value={in_notes}
                                                    onChange={(ev) => {
                                                        set_in_notes(ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="contained"
                                                fullWidth
                                                loading={attemptingRegister}
                                                onClick={() => {
                                                    setAttemptingRegister(true);
                                                    registerForEvent(event.id, {
                                                        name: in_name,
                                                        email: in_email,
                                                        phone: in_phone,
                                                        unit: in_unit_type + in_unit_number,
                                                        group: in_group,
                                                        notes: in_notes,
                                                        objects: in_objects,
                                                    })
                                                        .then((success) => {
                                                            setAttemptingRegister(false);
                                                            if (success) {
                                                                gaEvents.events.register(event.id);
                                                                setSuccessState("success");
                                                                setRegisterInProgress(false);
                                                                resetInputs();
                                                                setEvent({ ...event, registration_count: event.registration_count + 1 });
                                                            } else {
                                                                setSuccessState("fail");
                                                                setRegisterInProgress(false);
                                                                resetInputs();
                                                                console.warn("Failed to register for event");
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            setAttemptingRegister(false);
                                                            setErrorState(err);
                                                            setSuccessState("fail");
                                                            setRegisterInProgress(false);
                                                            resetInputs();
                                                            console.warn("Failed to register for event");
                                                        });
                                                }}
                                            >
                                                Register
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12} md={9} sx={{ p: 3 }}>
                                        <RichRender html={event.description} />
                                    </Grid>
                                    <Grid item xs={12} md={3} spacing={2}>
                                        <Stack spacing={1}>
                                            <Stack direction={"row"} spacing={1}>
                                                <TodayIcon />
                                                <Typography variant="body2">{dayjs(event.start).format(dateFormat)}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={1}>
                                                <EventIcon />
                                                <Typography variant="body2">{dayjs(event.end).format(dateFormat)}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={1}>
                                                <PinDropIcon />
                                                <Typography variant="body2">{event.location}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} spacing={1}>
                                                <PeopleIcon />
                                                <Typography variant="body2">{event.registration_count} registrants</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack spacing={2}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 6 }}
                                                onClick={() => {
                                                    setRegisterInProgress(true);
                                                    setSuccessState("unset");
                                                }}
                                            >
                                                Register
                                            </Button>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                onClick={() => {
                                                    navigate("/events");
                                                }}
                                            >
                                                View all events
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Paper>
            ) : (
                <div>Event Not Found</div>
            )}
        </Container>
    );
};

export default EventPage;
